import React, { useEffect, useContext } from "react";

import PageLayout from "../components/layout/page-layout";
import { PageContext, pages } from "../context/page-context";

import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import style from "../styles/page-styles/form-submitted.module.scss";

export default () => {
  const pageContext = useContext(PageContext);
  useEffect(() => {
    pageContext.setCurrentPage(pages.formSubmitted);
  }, [pageContext.currentPage]);
  return (
    <PageLayout>
      <section className={`container ${style.pageContent}`}>
        <IoMdCheckmarkCircleOutline size="6rem" style={{ color: "green" }} />
        <h3>Thank you for contacting us, we will reply as quickly as possible.</h3>
      </section>
    </PageLayout>
  );
};
